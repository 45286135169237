<template>
<v-container v-if="stores.length > 0">
  <div class="main">
    <v-hover :key='index' v-for="(i, index) in stores" v-slot="{ hover }">
    <div
      class="pa-3 item"
      color="white"
        @click="navigate(i._id)"
    >
      <v-img
        lazy-src="https://picsum.photos/id/11/10/6"
         :src="i.logo ? DomainImage+i.logo :'https://storage.googleapis.com/gravicart/Rectangle%2010.png'"
        class="vimg"
      >
        <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out v-card--reveal white--text colorr"
            style="height: 100%;"
          >
           {{roundUp(i.dis, 2)}} K.M
          </div>
        </v-expand-transition>
      </v-img>
      <div class="pt-6">
        <div class="firstline">
        <div>
         <div v-if="i.category" class="SUPERMARKET">
             {{i.category}}
         </div>
          <div v-else class="SUPERMARKET">
             ----------
         </div>
        </div>
        <v-spacer/>
        <div>
            <div v-if="i.state" class="State">
                {{i.state}}
            </div>
             <div v-else class="State">
               ----------
            </div>
        </div>
        </div>
         <v-divider class="mt-5" light></v-divider>
        <div>
        <p class="Stores-Head">
          {{i.name}}
        </p>
        <div class="mb-4">
         <v-icon
           small
           color="red"
          >
         mdi-pin
       </v-icon>
       <span class="Area">
           {{i.address}}
       </span>
        </div>

        <div class="time">
            <div>
                <div class="clock">Open by {{BussinessTime(i.BussinessTime).open}}</div>
                <div class="clock">Closes by {{BussinessTime(i.BussinessTime).close}} </div>
            </div>
            <v-spacer/>
             <v-btn
             @click="navigate(i._id)"
             dark
              small
              color="#1c63ba"
              >
              <span class="btntex"> View store items</span>
              </v-btn>
        </div>
             <div>
            <div class="clock"><small>{{roundUp(i.dis, 2)}} Kilometer Away</small> </div>
            </div>
        </div>
      </div>
    </div>
  </v-hover>
  </div>
</v-container>
  <v-container v-else>
  <div class="main">
    <v-hover :key='index' v-for="(i, index) in 8" v-slot="{ hover }">
    <div
      class="mx-auto pa-3 item"
      color="white"
    >
      <v-img
        lazy-src="https://picsum.photos/id/11/10/6"
         src="https://picsum.photos/id/11/10/6"
        class="vimg"
      >
        <v-expand-transition>
          <div
            v-if="hover"
            class="d-flex transition-fast-in-fast-out v-card--reveal white--text colorr"
            style="height: 100%;"
          >
           {{roundUp('0000', 2)}} K.M
          </div>
        </v-expand-transition>
      </v-img>
      <div class="pt-6">
        <div class="firstline">
        <div>
          <div class="SUPERMARKET">
             ----------
         </div>
        </div>
        <v-spacer/>
        <div>
             <div class="State">
               ----------
            </div>
        </div>
        </div>
         <v-divider class="mt-5" light></v-divider>
        <div>
        <p class="Stores-Head">
         ---------
        </p>
        <div class="mb-4">
         <v-icon
           small
           color="red"
          >
         mdi-pin
       </v-icon>
       <span class="Area">
        ----------
       </span>
        </div>

        <div class="time">
            <div>
                <div class="clock"> -------</div>
                <div class="clock">------- </div>
            </div>
            <v-spacer/>
             <v-btn
             dark
              small
              color="#1c63ba"
              >
              <span class="btntex">-------</span>
              </v-btn>
        </div>
             <div>
            <div class="clock"><small>-------- Kilometer Away</small> </div>
            </div>
        </div>
      </div>
    </div>
  </v-hover>
  </div>
</v-container>
</template>

<script>
import {DomainImage} from '../APIs'

export default {
    metaInfo: {
    title: `Near By Stores`,
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'Ecommerce'}
    ]
  },
  created(){
    this.getLocation()
    this.loadForeBack()
    this.DomainImage = DomainImage
    this.$store.commit("setLoading", true)
  },
    data: () => ({
    }),
computed:{
  totalItemsStores(){
     return this.$store.getters.totalItemsStores;
  },
  stores(){
       return this.$store.getters.Stores;
  },
  stateDate(){
         return this.$store.getters.stateDate;
    },
},
 methods:{
   BussinessTime(payload){
     if(!payload){
       let n = {open:"", close: ""}
       return n
     }
     var d = new Date()
     var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";

     var n = weekday[d.getDay()];
     let r; 
    var result = Object.keys(payload).map((key) => [key, payload[key]]);
     result.forEach(element => {
       if(element[0] == n){
         r = element
       }
     });
    return r[1]
    
   },
   roundUp(num, precision) {
      precision = Math.pow(10, precision)
      return Math.ceil(num * precision) / precision
    },
   getLocation(){
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(this.successfulcallback, this.loadForeBack);
  }else{
    this.loadForeBack()
  }
},
   loadForeBack(){
       let payload= {
            lat: this.stateDate.lat,
            lng: this.stateDate.lng,
          }
          this.$store.commit("setSnackbar", true)
          this.$store.commit("setServerMessage", "Please turn on location for better experience")
          this.$store.dispatch('GetNearStore', payload)
   },
   successfulcallback(payload){
        let payload2= {
            lat: payload.coords.latitude,
            lng: payload.coords.longitude,
          }
          this.$store.dispatch('GetNearStore', payload2)
   },
    navigate(payload){
        this.$router.push({path:`/Store/${payload}`});
    },
 }
}
</script>

<style scoped>
.main{
    min-height: 100vh;
    padding-top: 0px;
    display: flex;
    flex-wrap: wrap;
}
.main::-webkit-scrollbar {
  display: none !important;
}
.main {
  -ms-overflow-style: none!important;  /* IE and Edge */
  scrollbar-width: none!important;  /* Firefox */
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
.vimg{
    border-radius: 10px;
}
.firstline{
    display: flex;
}
.item{
  width: 265px;
  margin: 10px;
  height: 450px;
  padding: 24px 25px 15px;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: pointer;
}
.SUPERMARKET {
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #000039;
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.State {
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  width: 80px;
 font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-image: linear-gradient(to top, #f2994a, #eed72b);
}
.Stores-Head{
  margin: 20px 47px 0px 5px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #1156aa;
  text-transform: capitalize;
}
.Area{
  margin: 0 0 0 5px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #374146;
}
.time{
    display: flex;
    justify-content: center;
    align-items: center;
}
.clock{
  margin: 10px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #000039;
}
.btntex{
  font-size: 9px !important;  
}
.colorr{
    background-color: #1c63ba;
}
</style>